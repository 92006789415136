import React from 'react';
import styled, { css } from 'styled-components';

import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import MDXComponents from 'src/components/mdx-components';

import { Section } from 'src/components/molecules/Section';
import { Button } from 'src/components/atoms/Button';

const MySection = styled.div`
  text-align: center;
  background: #f2f4fa;
  padding: 60px 0;
`;

export default function LegacySection({ heading, content, contentNode, callouts, ctas, customData }) {
  const ctaBlocks = callouts.filter((block) => block.__typename == 'DatoCmsCtaBlock');
  customData = JSON.parse(customData);
  return (
    <MDXProvider components={MDXComponents}>
      <MySection {...customData?.flags}>
        <h2>{heading}</h2>
        {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        {ctas.concat(ctaBlocks).map((cta, i) => {
          const ctaFlags = i > 0 ? { secondary: true } : {};
          return (
            <Button to={cta.url || cta.href} key={i} {...ctaFlags}>
              {cta.text}
            </Button>
          );
        })}
      </MySection>
    </MDXProvider>
  );
}
